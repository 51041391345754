import {HttpClient} from "../HttpClient";
import {AxiosResponse} from "axios";
import {createApiAxiosResponse} from "../../fixtures/Api.fixture";
import {createUser} from "../../fixtures/User.fixture";

export enum UserRoles {
    User = "user",
    Admin = "admin",
}

export type User = {
    id: string;
    email: string;
    terminal: string;
    role: UserRoles;
};

export default class UsersClient extends HttpClient {
    async fetchUsers(code: string): Promise<AxiosResponse<any>> {
        const url = `/v1/user/terminal/${code}`;

        try {
            return await this.get<AxiosResponse<any>>(url);
        } catch (error) {
            console.error(`Failed to fetch user for terminal ${code}:`, error);
            throw error;
        }
    }

    async addUser(terminalCode: String, email: String) {
        const url = `/v1/user`;

        try {
            await this.post(url, {"email": email, "terminalCode": terminalCode});
        } catch (error) {
            console.error(`Failed to add user to terminal ${terminalCode}:`, error);
            throw error
        }
    }

    async deleteUser(terminalCode: String, userId: String) {
        const url = `/v1/user/delete`;

        try {
            await this.post(url, {"id": userId, "terminalCode": terminalCode});
        } catch (error) {
            console.error(`Failed to delete user ${userId} from terminal ${terminalCode}:`, error);
            throw error
        }
    }
}
