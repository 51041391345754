import React, {useEffect, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import {useRedirectToRoute} from "../../common/hooks/useRedirectToRoute";
import {AppRoutes} from "../../../config/routes";
import {FormattedMessage, useIntl} from "react-intl";
import {
    McLoadingIndicator,
    McNotification,
    McTable,
    McOption,
    McButton,
    McSelect,
    McTabBar,
    McTab
} from "@maersk-global/mds-react-wrapper";
import {TableColumn} from "@maersk-global/mds-components-core/mc-table/types";
import {DependencyContainer} from "../../../http/DependencyContainer";
import EditDefaultValuesDrawer from "./EditDefaultValuesDrawer/EditDefaultValuesDrawer";
import EditWeeklyValuesDrawer from "./EditWeeklyValuesDrawer/EditWeeklyValuesDrawer";
import {mapCapacityType} from "./utils/capacityTypeMapper";
import EditOccValuesDrawer from "./EditOccValuesDrawer/EditOccValuesDrawer";
import {modifyDate, modifyDateWeek} from "../../common/helpers/modify-date";
import AddDefaultValuesDrawer from "./AddDefaultValuesDrawer/AddDefaultValuesDrawer";
import useAuth from "../../common/hooks/useAuth";
import { terminals } from "../../common/helpers/terminals";

const {octwService} = new DependencyContainer();

const CapacityIdContainer: React.FC = () => {
    const {userData} = useAuth();
    const navigate = useNavigate();
    const occTableRef = useRef(null);
    const defaultTableRef = useRef(null);
    const weeklyTableRef = useRef(null);
    const {id} = useParams<{ id: string }>();
    const [selectedTerminal, setSelectedTerminal] = useState(id || "");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [occData, setOccData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    const [weeklyData, setWeeklyData] = useState([]);

    const [selectedOccRow, setSelectedOccRow] = useState(null)

    const [selectedDefaultRow, setSelectedDefaultRow] = useState(null);
    const [selectedWeeklyData, setSelectedWeeklyData] = useState(null);
    const [selectedWeeklyRow, setSelectedWeeklyRow] = useState([]);

    const [selectedWeeklyRows, setSelectedWeeklyRows] = useState([]);

    const [selectedAddDefaultValues, setSelectedAddDefaultValues] = useState(null);

    const {formatMessage} = useIntl();
    const redirectToRoute = useRedirectToRoute();

    const handleSelectChange = async (selectedCode) => {
        if (!selectedCode) return
        setSelectedTerminal(selectedCode);
        setLoading(true);
        setError(null);

        try {
            navigate(`/capacity/${selectedCode}`);
            await redirectToRoute(AppRoutes.CapacityId, {id: selectedCode});
            fetchCapacityData(selectedCode);
        } catch (err) {
            setError(formatMessage({id: "genericErrorMessage"}));
            setLoading(false);
        }
    };

    const fetchCapacityData = async (terminalCode: string) => {
        setLoading(true);
        setError(null);

        try {
            // Make a single API call to get both capacityEntities and capacityTemplateEntities
            const response = await octwService.getCapacityForTerminal(terminalCode);
            const occResponse = await octwService.getOccForTerminal(terminalCode);

            // Log the response to inspect it
            console.log("API Response:", response);

            // Destructure directly from response, not response.data
            const {capacityEntities, capacityTemplateEntities} = response;

            setApiResponse(response)
            if (occResponse) {
                // @ts-ignore
                setOccData(occResponse.capacityEntries);
            } else {
                setOccData([]);
            }

            // Handling multiple default entries
            if (Array.isArray(capacityTemplateEntities) && capacityTemplateEntities.length > 0) {
                // Update defaultData based on the fetched capacityTemplateEntities
                const defaultEntries = capacityTemplateEntities.map((entry, index) => {
                    const previousStartDate = capacityTemplateEntities[index - 1]?.startDate?.split("T")[0]
                    const startDate = entry.startDate.split("T")[0]
                    const nextStartDate = capacityTemplateEntities[index + 1]?.startDate?.split("T")[0]
                    // console.log("Next start date is:", nextStartDate )
                    // console.log("Next start date - 1 day is ", (modifyDate(nextStartDate, "remove")))
                    // console.log("Previous start date is:", previousStartDate)
                    return ({
                        id: entry.terminalCodeAndStartDate,
                        startDate: entry.startDate,
                        previousStartDate: previousStartDate,
                        nextStartDate: nextStartDate,
                        validityPeriod: startDate.replaceAll("-", "/") + " - " + (nextStartDate ? (modifyDate(nextStartDate, "remove").replaceAll("-", "/")) : "2100/01/01"),
                        moves: entry.moves,
                        moveCountStretchPercentage: entry.moveCountStretchPercentage,
                        moveCountCriticalPercentage: entry.moveCountCriticalPercentage,
                        moveCountStretchNumber: entry.moveCountStretchNumber,
                        moveCountCriticalNumber: entry.moveCountCriticalNumber,
                        moveCountStretchNumberOfWeeks: entry.moveCountStretchNumberOfWeeks,
                        moveCountCriticalNumberOfWeeks: entry.moveCountCriticalNumberOfWeeks,
                        moveCountStretch: entry.moveCountStretchPercentage && `${entry.moveCountStretchNumber} (${entry.moveCountStretchPercentage}%)` || '',
                        moveCountCritical: entry.moveCountCriticalPercentage && `${entry.moveCountCriticalNumber} (${entry.moveCountCriticalPercentage}%)` || '',
                        yardCapacity: entry.yardCapacity,
                        reeferPlugs: entry.reeferPlugs,
                    })
                })

                // @ts-ignore
                setDefaultData(defaultEntries);
            } else {
                console.warn("capacityTemplateEntities is missing or empty in the response data");
                setDefaultData([]);
            }

            if (Array.isArray(capacityEntities) && capacityEntities.length > 0) {
                // Update weeklyData based on the fetched capacityEntities
                const weekly = capacityEntities.map((week, index) => ({
                    date: `${week.date}`,  // Adjust this if you need to use actual dates
                    moveCount: `${week.moves}`,
                    yardCapacity: `${week.yardCapacity}`,
                    moveCountStretchPercentage: week.moveCountStretchPercentage,
                    moveCountStretchNumber: week.moveCountStretchNumber,
                    moveCountStretch: week.moveCountStretchPercentage && `${week.moveCountStretchNumber} (${week.moveCountStretchPercentage}%)` || '',
                    moveCountStretchNumberOfWeeks: week.moveCountStretchNumberOfWeeks,
                    moveCountCriticalNumber: week.moveCountCriticalNumber,
                    moveCountCritical: week.moveCountCriticalPercentage && `${week.moveCountCriticalNumber} (${week.moveCountCriticalPercentage}%)` || '',
                    moveCountCriticalNumberOfWeeks: week.moveCountCriticalNumberOfWeeks,
                    moveCountCriticalPercentage: week.moveCountCriticalPercentage,
                    reeferPlugs: `${week.reeferPlugs}`,
                    status: week.overwrite ? 'Adjusted' : 'Default',
                }));
                // @ts-ignore
                setWeeklyData(weekly);
            } else {
                console.warn("capacityEntities is missing or empty in the response data");
                setWeeklyData([]);
            }

        } catch (err) {
            console.error("Error fetching capacity data:", err); // Log the error object for debugging
            setError(formatMessage({id: "genericErrorMessage"}));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedTerminal) {
            fetchCapacityData(selectedTerminal);
        }
    }, [selectedTerminal]);

    const defaultColumns: TableColumn[] = [
        {id: 'moves', label: 'Move count', align: 'left'},
        {id: 'moveCountStretch', label: 'Threshold L2', align: 'left'},
        {id: 'moveCountCritical', label: 'Threshold L3', align: 'left'},
        {id: 'yardCapacity', label: 'Yard Capacity', align: 'left'},
        {id: 'reeferPlugs', label: 'Reefer plugs', align: 'left'},
        {id: 'validityPeriod', label: 'Validity period', align: 'left'}
    ];

    const weeklyColumns: TableColumn[] = [
        {id: 'date', label: 'Date', align: 'left'},
        {id: 'moveCount', label: 'Move count', align: 'left'},
        {id: 'moveCountStretch', label: 'Threshold L2', align: 'left'},
        {id: 'moveCountCritical', label: 'Threshold L3', align: 'left'},
        {id: 'yardCapacity', label: 'Yard Capacity', align: 'left'},
        {id: 'reeferPlugs', label: 'Reefer plugs', align: 'left'},
        {id: 'status', label: 'Status', align: 'left'},
    ];

    // Function to handle row click in the default table
    const onDefaultRowClick = (e) => {
        const row = e.detail.rowData;
        setSelectedDefaultRow(row);
    };
    const onWeeklySelectChange = (e) => {
        setSelectedWeeklyRows(e.detail)
    }
    const onWeeklyRowClick = (e) => {
        const row = e.detail.rowData;
        console.log("Selected weekly row:", row);

        // Check if multiple rows selected, if all numbers same then set it, else set all to 0 0 0
        setSelectedWeeklyData({
            // @ts-ignore
            moves: parseInt(row.moveCount),
            yardCapacity: parseInt(row.yardCapacity),
            reeferPlugs: parseInt(row.reeferPlugs),
            moveCountStretchPercentage: parseInt(row.moveCountStretchPercentage),
            moveCountStretchNumber: parseInt(row.moveCountStretchNumber),
            moveCountStretchNumberOfWeeks: parseInt(row.moveCountStretchNumberOfWeeks),
            moveCountCriticalPercentage: parseInt(row.moveCountCriticalPercentage),
            moveCountCriticalNumber: parseInt(row.moveCountCriticalNumber),
            moveCountCriticalNumberOfWeeks: parseInt(row.moveCountCriticalNumberOfWeeks),
        })

        // @ts-ignore
        setSelectedWeeklyRow([row.date]);
    };

    // @ts-ignore
    return (
        <div className="CapacityContainer">
            {apiResponse && <EditOccValuesDrawer
                // @ts-ignore
                occValues={selectedOccRow}
                selectedTerminal={selectedTerminal}
                open={Boolean(selectedOccRow)}
                fetchRules={() => fetchCapacityData(selectedTerminal)}
                setOpen={() => setSelectedOccRow(null)}
            />}

            {apiResponse && selectedAddDefaultValues && <AddDefaultValuesDrawer
                // @ts-ignore
                defaultValues={selectedAddDefaultValues}
                selectedTerminal={selectedTerminal}
                // @ts-ignore
                id={selectedDefaultRow?.id}
                open={Boolean(selectedAddDefaultValues)}
                fetchRules={() => fetchCapacityData(selectedTerminal)}
                setOpen={() => setSelectedAddDefaultValues(null)}
            />}

            {apiResponse && selectedDefaultRow && <EditDefaultValuesDrawer
                // @ts-ignore
                defaultValues={selectedDefaultRow}
                selectedTerminal={selectedTerminal}
                // @ts-ignore
                id={selectedDefaultRow?.id}
                open={Boolean(selectedDefaultRow)}
                fetchRules={() => fetchCapacityData(selectedTerminal)}
                setOpen={() => setSelectedDefaultRow(null)}
            />}

            {apiResponse && selectedWeeklyData && <EditWeeklyValuesDrawer
                // @ts-ignore
                defaultValues={selectedWeeklyData}
                selectedTerminal={selectedTerminal}
                open={selectedWeeklyRow.length > 0}
                selectedWeeks={selectedWeeklyRow}
                fetchRules={() => {
                    setSelectedWeeklyRows([])
                    fetchCapacityData(selectedTerminal)
                }}
                setOpen={() => setSelectedWeeklyRow([])}
            />}

            <McSelect
                label={"Select terminal"}
                placeholder="Select terminal"
                value={selectedTerminal}
                optionselected={(e) => {
                    // @ts-ignore
                    handleSelectChange(e.target.value)
                }}
                name="terminal">
                {userData?.terminalCodes && terminals.filter(terminal => userData.terminalCodes.includes(terminal.code)).map((terminal) => (
                    <McOption key={terminal.code} value={terminal.code}>
                        {terminal.name}
                    </McOption>
                ))}
            </McSelect>

            {error && (
                <McNotification
                    appearance="error"
                    heading={formatMessage({id: "error"})}
                >
                    <FormattedMessage id="genericErrorMessage"/>
                </McNotification>
            )}
            <McTabBar>
                <McTab slot="tab" label="SOC"/>
                <div slot="panel">
                    {loading ? (
                        <McLoadingIndicator label={formatMessage({id: "fetchingData"})}/>
                    ) : (
                        selectedTerminal && (
                            <>

                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div>
                                        <h3>Default values</h3>
                                    </div>
                                    <McButton
                                        label="Add new start period"
                                        appearance="neutral"
                                        icon={"plus"}
                                        variant="outlined"
                                        click={() => {
                                            const lastEntry = defaultData[defaultData.length - 1];
                                            const latestData = {
                                                // @ts-ignore
                                                ...lastEntry,
                                                // @ts-ignore
                                                "startDate": modifyDateWeek(lastEntry.startDate.split("T")[0], "add"),
                                                // @ts-ignore
                                                "previousStartDate": lastEntry.startDate.split("T")[0],
                                                // @ts-ignore
                                                "nextStartDate": null,
                                            };
                                            setSelectedAddDefaultValues(latestData)
                                        }}
                                    />

                                </div>
                                <McTable
                                    ref={defaultTableRef}
                                    columns={defaultColumns}
                                    data={defaultData}
                                    rowclick={onDefaultRowClick}
                                />
                                <div>
                                    <h3>Weekly values</h3>
                                    {selectedWeeklyRows.length > 0 &&
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '0.75rem 1rem',
                                            backgroundColor: '#FAE5DA',
                                            borderRadius: '4px',
                                            marginBottom: '1rem',
                                            border: '1px solid #FF7A59'
                                        }}>
                                <span style={{fontSize: '1rem', fontWeight: 'bold', color: '#333'}}>
                                    {selectedWeeklyRows.length} week(s) selected
                                </span>
                                            <McButton
                                                label="Edit"
                                                appearance="secondary"
                                                variant="outlined"
                                                padding="compact"
                                                click={() => {
                                                    setSelectedWeeklyData({
                                                        // @ts-ignore
                                                        moves: parseInt(selectedWeeklyRows[0].moveCount),
                                                        // @ts-ignore
                                                        yardCapacity: parseInt(selectedWeeklyRows[0].yardCapacity),
                                                        // @ts-ignore
                                                        reeferPlugs: parseInt(selectedWeeklyRows[0].reeferPlugs),
                                                        // @ts-ignore
                                                        moveCountStretchPercentage: parseInt(selectedWeeklyRows[0].moveCountStretchPercentage),
                                                        // @ts-ignore
                                                        moveCountStretchNumber: parseInt(selectedWeeklyRows[0].moveCountStretchNumber),
                                                        // @ts-ignore
                                                        moveCountStretchNumberOfWeeks: selectedWeeklyRows[0].moveCountStretchNumberOfWeeks,
                                                        // @ts-ignore
                                                        moveCountCriticalPercentage: parseInt(selectedWeeklyRows[0].moveCountCriticalPercentage),
                                                        // @ts-ignore
                                                        moveCountCriticalNumber: parseInt(selectedWeeklyRows[0].moveCountCriticalNumber),
                                                        // @ts-ignore
                                                        moveCountCriticalNumberOfWeeks: selectedWeeklyRows[0].moveCountCriticalNumberOfWeeks,
                                                    })
                                                    // @ts-ignore
                                                    console.log("Selected weekly rows:", selectedWeeklyRows.map(row => row.date));
                                                    setSelectedWeeklyRow(
                                                        // @ts-ignore
                                                        selectedWeeklyRows.map(row => row.date))
                                                }}
                                            />
                                        </div>
                                    }
                                    <McTable
                                        // @ts-ignore
                                        rowclick={onWeeklyRowClick}
                                        selectchange={onWeeklySelectChange}
                                        select={true}
                                        ref={weeklyTableRef}
                                        columns={weeklyColumns}
                                        data={weeklyData}
                                    />
                                </div>
                            </>
                        )
                    )}
                </div>

                <McTab slot="tab" label={"OCC"}/>
                <div slot="panel">
                    {loading ? (
                        <McLoadingIndicator label={formatMessage({id: "fetchingData"})}/>
                    ) : (
                        selectedTerminal && (
                            <>
                                <div>
                                    <h3>Overlapping Connections Capacity</h3>
                                    <McTable
                                        ref={occTableRef}
                                        columns={[
                                            {id: 'from', label: 'From (PC)', align: 'left'},
                                            {id: 'to', label: 'To (OC)', align: 'left'},
                                            {id: 'vesselPairLimits', label: 'Vessel pair limits', align: 'left'},
                                        ]}
                                        // @ts-ignore
                                        data={occData && occData.map((occ) => ({
                                            // @ts-ignore
                                            from: mapCapacityType(occ.from),
                                            // @ts-ignore
                                            to: mapCapacityType(occ.to),
                                            // @ts-ignore
                                            vesselPairLimits: `${occ.vesselPairLimits}`
                                        }))}
                                        rowclick={(e) => {
                                            const row = e.detail.rowData;
                                            // @ts-ignore
                                            setSelectedOccRow(row);
                                        }}
                                    />
                                </div>
                            </>
                        )
                    )}
                </div>
            </McTabBar>
        </div>
    );
};

export default CapacityIdContainer;
