import React, {FunctionComponent} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AppRoutes} from "./config/routes";
import ForAuthenticated from "./features/common/ForAuthenticated";
import RulesContainer from "./features/octw/containers/RulesContainer";
import AppLayout from "./features/common/layouts/AppLayout";
import MessagesProvider from "./features/common/MessagesProvider";
import UsersContainer from "./features/users/UsersContainer";
import {RecoilRoot} from "recoil";
import CapacityIdContainer from "./features/capacity/containers/CapacityIdContainer";

const App: FunctionComponent = () => {
    return (
        <RecoilRoot>
            <MessagesProvider>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path={AppRoutes.OctwRules}
                            element={
                                <ForAuthenticated>
                                    <AppLayout>
                                        <RulesContainer/>
                                    </AppLayout>
                                </ForAuthenticated>
                            }
                        />
                        <Route
                            path={AppRoutes.CapacityId}
                            element={
                                <ForAuthenticated>
                                    <AppLayout>
                                        <CapacityIdContainer/>
                                    </AppLayout>
                                </ForAuthenticated>
                            }
                        />
                        <Route
                            path={AppRoutes.Users}
                            element={
                                <ForAuthenticated>
                                    <AppLayout>
                                        <UsersContainer/>
                                    </AppLayout>
                                </ForAuthenticated>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </MessagesProvider>
        </RecoilRoot>
    );
};

export default App;
