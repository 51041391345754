import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";

import {ErrorMessage, Field, Form, Formik, useFormik} from "formik";
import {McButton, McCalendar, McDateRange, McInputDate, McNotification} from "@maersk-global/mds-react-wrapper";
import z from "zod";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {useSnackbar} from "notistack";
import {AdjustableComponent, useClasses} from "../../common/hooks/useClasses";
import {FormattedMessage, useIntl} from "react-intl";
import FormikInputField from "../../common/components/FormikInputField/FormikInputField";
import {DependencyContainer} from "../../../http/DependencyContainer";
import Drawer from "../../common/components/Drawer/Drawer";

export type AddDefaultValuesDrawerStyles = {
    field: string;
    buttons: string;
};

export type AddDefaultValuesDrawerProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    fetchRules: () => void;
    selectedTerminal: string;
};

export type EditRuleFormShape = {
    terminalId: string;
    email: string;
};

const FormValidationSchema = z.object({
    terminalId: z.string(),
    email:  z.string()
});

const {usersService} = new DependencyContainer();

const AddUserDrawer: AdjustableComponent<
    AddDefaultValuesDrawerProps,
    AddDefaultValuesDrawerStyles
> = ({classes, open, setOpen, fetchRules, selectedTerminal}) => {
    const styles = useClasses(
        {
            field: "AddDefaultValuesDrawer__field",
            buttons: "AddDefaultValuesDrawer__buttons",
        },
        classes
    );
    const {enqueueSnackbar} = useSnackbar();
    const {formatMessage} = useIntl();
    const formikRef = useRef(null);
    const onClose = () => {
        setOpen(false);
    };
    const getFormInitialValues = (): EditRuleFormShape => {
        return {
            terminalId: selectedTerminal,
            email: ''
        };
    };

    const onSubmit = async (values, {setSubmitting}) => {
        console.log("On submit");
        setSubmitting(true);
        try {
            await usersService.addUser(selectedTerminal, values.email);
            enqueueSnackbar("User added", {
                variant: "success",
            });
            await fetchRules();
            onClose();
        } catch (error) {
            const message = "User not found for email: " + values.email;
            enqueueSnackbar(message, {
                variant: "error",
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Drawer
            title={"Add user to " + selectedTerminal}
            open={open}
            setOpen={setOpen}
            onRequestClose={onClose}
            noFooter
        >
            <Formik
                initialValues={getFormInitialValues()}
                enableReinitialize
                validationSchema={toFormikValidationSchema(FormValidationSchema)}
                onSubmit={onSubmit}
                innerRef={formikRef}
            >
                {({isSubmitting}) => (
                    <Form>
                        <div>
                            <Field
                                label={"Email"}
                                type="text"
                                name="email"
                                component={FormikInputField}
                            />
                            <ErrorMessage name="email" component="div"/>
                        </div>

                        <Field type="hidden" name="terminalId"/>
                        <div className={styles.buttons}>
                            <McButton disabled={isSubmitting} type="submit">
                                <FormattedMessage
                                    id={
                                        isSubmitting
                                            ? "updateRuleDrawerFormSubmitting"
                                            : "updateRuleDrawerFormSubmit"
                                    }
                                />
                            </McButton>
                            <McButton type="button" appearance="neutral" click={() => onClose()}>
                                <FormattedMessage id="cancel"/>
                            </McButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default AddUserDrawer;
