import {DependencyContainer} from "../DependencyContainer";
import {User} from "../clients/UsersClient";

export default class UsersService {
  constructor(private readonly factory: DependencyContainer) {}

  async getUsers(code: String): Promise<User[]> {
    // @ts-ignore
    const response = await this.factory.usersClient.fetchUsers(code);
    return response.data;
  }

  async addUser(terminalCode: String, email: String): Promise<void> {
    await this.factory.usersClient.addUser(terminalCode, email);
  }

  async deleteUser(terminalCode: String, userId: String): Promise<void> {
    await this.factory.usersClient.deleteUser(terminalCode, userId);
  }
}
