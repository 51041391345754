import {Maybe} from "../../../common/types";
import {EditRuleFormShape} from "../EditWeeklyValuesDrawer/EditWeeklyValuesDrawer";

export type WeeklyValueDto = {
    moves: Maybe<number>;
    yardCapacity: Maybe<number>;
    reeferPlugs: Maybe<number>;
    terminalCode: Maybe<string>;
    weeks: Maybe<string[]>;
    moveCountStretchPercentage: Maybe<number>;
    moveCountStretchNumber: Maybe<number>;
    moveCountStretchNumberOfWeeks: Maybe<number>;
    moveCountCriticalPercentage: Maybe<number>;
    moveCountCriticalNumber: Maybe<number>;
    moveCountCriticalNumberOfWeeks: Maybe<number>;
};

export const createWeeklyValueDto = (
    formData: EditRuleFormShape,
): WeeklyValueDto => {
    return {
        moves: formData.moves,
        yardCapacity: formData.yardCapacity,
        reeferPlugs: formData.reeferPlugs,
        terminalCode: formData.terminalCode,
        weeks: formData.selectedWeeks,
        moveCountStretchPercentage: formData.moveCountStretchPercentage,
        moveCountStretchNumber: formData.moveCountStretchNumber,
        moveCountStretchNumberOfWeeks: formData.moveCountStretchNumberOfWeeks,
        moveCountCriticalPercentage: formData.moveCountCriticalPercentage,
        moveCountCriticalNumber: formData.moveCountCriticalNumber,
        moveCountCriticalNumberOfWeeks: formData.moveCountCriticalNumberOfWeeks,
    };
};
