import {Maybe} from "../../../common/types";
import {EditRuleFormShape} from "../EditDefaultValuesDrawer/EditDefaultValuesDrawer";

export type DefaultValueDto = {
    id: string;
    startDate: string;
    moves: Maybe<number>;
    yardCapacity: Maybe<number>;
    reeferPlugs: Maybe<number>;
    terminalCode: Maybe<string>;
    moveCountStretchPercentage: Maybe<number>;
    moveCountStretchNumber: Maybe<number>;
    moveCountStretchNumberOfWeeks: Maybe<number>;
    moveCountCriticalPercentage: Maybe<number>;
    moveCountCriticalNumber: Maybe<number>;
    moveCountCriticalNumberOfWeeks: Maybe<number>;
};

export const createDefaultValueDto = (
    formData: EditRuleFormShape,
): DefaultValueDto => {
    return {
        id: formData.id,
        startDate: formData.startDate,
        moves: formData.moves,
        yardCapacity: formData.yardCapacity,
        reeferPlugs: formData.reeferPlugs,
        terminalCode: formData.terminalCode,
        moveCountStretchPercentage: formData.moveCountStretchPercentage,
        moveCountStretchNumber: formData.moveCountStretchNumber,
        moveCountStretchNumberOfWeeks: formData.moveCountStretchNumberOfWeeks,
        moveCountCriticalPercentage: formData.moveCountCriticalPercentage,
        moveCountCriticalNumber: formData.moveCountCriticalNumber,
        moveCountCriticalNumberOfWeeks: formData.moveCountCriticalNumberOfWeeks,
    };
};
