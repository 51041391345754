import {DependencyContainer} from "../DependencyContainer";
import {OctwRule} from "../clients/OctwClient";
import {UpdateRuleDto} from "../../features/octw/dto/UpdateRuleDto";
import {DefaultValueDto} from "../../features/capacity/containers/dto/DefaultValueDto";
import {WeeklyValueDto} from "../../features/capacity/containers/dto/WeeklyValueDto";
import {UpdateOccDto} from "../../features/capacity/containers/dto/OccDto";

export default class OctwService {
    constructor(private readonly factory: DependencyContainer) {
    }

    async getRules(): Promise<OctwRule[]> {
        const response = await this.factory.octwClient.fetchRules();
        const results = response.data;
        if (Array.isArray(results)) {
            return results;
        }
        return [results];
    }

    async getCapacityForTerminal(code: string): Promise<any> {
        const response = await this.factory.octwClient.getCapacityForTerminal(code);
        return response.data
    }

    async updateRule(dto: UpdateRuleDto): Promise<void> {
        await this.factory.octwClient.updateRule(dto);
    }
    async updateDefaultValues(dto: DefaultValueDto): Promise<void> {
        await this.factory.octwClient.updateDefaultValue(dto);
    }
    async createDefaultValues(dto: DefaultValueDto): Promise<void> {
        await this.factory.octwClient.createDefaultValue(dto);
    }
    async deleteDefaultValue(id: string): Promise<void> {
        await this.factory.octwClient.deleteDefaultValue(id);
    }
    async updateOccValues(dto: UpdateOccDto): Promise<void> {
        await this.factory.octwClient.updateOccValues(dto);
    }
    async updateWeeklyValues(dto: WeeklyValueDto): Promise<void> {
        await this.factory.octwClient.updateWeeklyValue(dto);
    }

    async getUser(): Promise<any> {
        const response = await this.factory.octwClient.getUser()
        return response.data
    }

    async getOccForTerminal(terminalCode: string) {
        const response = await this.factory.octwClient.getOccCapacityForTerminal(terminalCode);
        return response.data
    }
}
