const capacityTypeMap = {
    TRUNK: "Gemini Mainliners",
    SHUTTLE: "Gemini Shuttle",
    NS_MAIN: "Non-Gemini Mainliner",
    FEEDER: "Feeder (Own)"
};

const reverseCapacityTypeMap = Object.fromEntries(
    Object.entries(capacityTypeMap).map(([key, value]) => [value, key])
);

export const mapCapacityType = (capacityType) => {
    return capacityTypeMap[capacityType] || capacityType;
};

export const mapLabelToCapacityType = (label) => {
    return reverseCapacityTypeMap[label] || label;
};


