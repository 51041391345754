import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Drawer from "../../../common/components/Drawer/Drawer";
import {
    AdjustableComponent,
    useClasses,
} from "../../../common/hooks/useClasses";
import {ErrorMessage, Field, Form, Formik, useFormik} from "formik";
import {Maybe} from "../../../common/types";
import FormikInputField from "../../../common/components/FormikInputField/FormikInputField";
import {McButton} from "@maersk-global/mds-react-wrapper";
import z from "zod";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {DependencyContainer} from "../../../../http/DependencyContainer";
import {useSnackbar} from "notistack";
import {useIntl} from "../../../common/hooks/useIntl";
import FormattedMessage from "../../../common/FormattedMessage";
import {createDefaultValueDto, DefaultValueDto} from "../dto/DefaultValueDto";
import {createOccDto} from "../dto/OccDto";

export type EditOccValuesDrawerStyles = {
    field: string;
    buttons: string;
};

export type EditOccValuesDrawerProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    fetchRules: () => void;
    selectedTerminal: string;
    occValues: Map<string, number>;
};

export type EditOccFormShape = {
    terminalCode: Maybe<string>;
    from: Maybe<string>;
    to: Maybe<string>;
    vesselPairLimits: Maybe<number>;
};

const FormValidationSchema = z.object({
    terminalCode: z.string(),
    from: z.string(),
    to: z.string(),
    vesselPairLimits: z.number({message: "Value should be a number"}).min(0).max(999999),
});

const {octwService} = new DependencyContainer();

const EditOccValuesDrawer: AdjustableComponent<
    EditOccValuesDrawerProps,
    EditOccValuesDrawerStyles
> = ({classes, open, setOpen, fetchRules, selectedTerminal, occValues}) => {
    const styles = useClasses(
        {
            field: "EditOccValuesDrawer__field",
            buttons: "EditOccValuesDrawer__buttons",
        },
        classes
    );
    const {enqueueSnackbar} = useSnackbar();
    const {formatMessage} = useIntl();

    const formikRef = useRef(null); // Create a ref for Formik

    const onClose = () => {
        setOpen(false);
    };

    const getFormInitialValues = (): EditOccFormShape => {
        return {
            terminalCode: selectedTerminal,
            from: occValues && occValues["from"],
            to: occValues && occValues["to"],
            vesselPairLimits: occValues && occValues["vesselPairLimits"]
        };
    };

    const onSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);
        const dto = createOccDto(values);
        try {
            await octwService.updateOccValues(dto);
            enqueueSnackbar("OCC values updated", {
                variant: "success",
            });
            await fetchRules();
            onClose();
        } catch (error) {
            const message = formatMessage({id: "genericErrorMessage"});
            enqueueSnackbar(message, {
                variant: "error",
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Drawer
            title={"Edit Overlapping Connections Capacity"}
            open={open}
            setOpen={setOpen}
            onRequestClose={onClose}
            noFooter
        >
            <Formik
                initialValues={getFormInitialValues()}
                enableReinitialize
                validationSchema={toFormikValidationSchema(FormValidationSchema)}
                onSubmit={onSubmit}
                innerRef={formikRef} // Pass the ref to Formik
            >
                {({isSubmitting}) => (
                    <Form>
                        <div style={{display: "flex", gap: "1rem"}}>
                            <Field
                                label={"From (PC)"}
                                type="text"
                                name="from"
                                disabled
                                component={FormikInputField}
                            />
                            <ErrorMessage name="from" component="div"/>
                            <Field
                                label={"To (OC)"}
                                type="text"
                                name="to"
                                disabled
                                component={FormikInputField}
                            />
                            <ErrorMessage name="to" component="div"/>
                            <Field
                                label={"Vessel pair limits"}
                                type="number"
                                name="vesselPairLimits"
                                component={FormikInputField}
                            />
                            <ErrorMessage name="number" component="div"/>
                        </div>

                        <Field type="hidden" name="hubId"/>
                        <div className={styles.buttons}>
                            <McButton disabled={isSubmitting} type="submit">
                                <FormattedMessage
                                    id={
                                        isSubmitting
                                            ? "updateRuleDrawerFormSubmitting"
                                            : "updateRuleDrawerFormSubmit"
                                    }
                                />
                            </McButton>
                            <McButton type="button" appearance="neutral" click={() => onClose()}>
                                <FormattedMessage id="cancel"/>
                            </McButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default EditOccValuesDrawer;
