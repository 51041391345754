import React, { FunctionComponent, useEffect, useState } from "react";
import { DependencyContainer } from "../../../http/DependencyContainer";
import { useRecoilState } from "recoil";
import { octwRulesAtom } from "../../../state";
import {
  McCard,
  McLoadingIndicator,
  McNotification,
} from "@maersk-global/mds-react-wrapper";
import Property from "../../common/components/Property/Property";
import { useIntl } from "../../common/hooks/useIntl";
import FormattedMessage from "../../common/FormattedMessage";
import OctwAdminView from "../components/OctwAdminView";
import useAuth from "../../common/hooks/useAuth";

const { octwService } = new DependencyContainer();

const RulesContainer: FunctionComponent = () => {
  const [rules, setRules] = useRecoilState(octwRulesAtom);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { isAdmin } = useAuth();

  const fetchRules = () => {
    setLoading(true);
    octwService
      .getRules()
      .then((rules) => {
        setRules(rules);
        setError(undefined);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchRules();
  }, []);

  return (
    <div className="RulesContainer">
      <h2 className="container-title">
        <FormattedMessage id="rules" />
      </h2>
      <div className="RulesContainer__cards">
          <OctwAdminView fetchRules={fetchRules} />
      </div>
    </div>
  );
};

export default RulesContainer;
